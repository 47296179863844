.feedback-modal {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    max-width: 360px;
    .modal {
        &__header {
        }
        &__body {
            .feedback-rating {
                padding-right: 1rem;
                svg {
                    &.empty {
                        fill: #dcdcdc;
                    }
                    &.full {
                        fill: #f3c539;
                    }
                }
            }
            .order-rating {
                margin: 0.5rem 0;
                color: rgba(158, 158, 158, 0.7);
                font-size: 13px;
            }
        }
        &__footer {
            display: flex;
            justify-content: flex-end;

            margin-top: 0.5rem;
            padding: 1rem;
            border-top: 1px solid #eaf0f4;
        }
        &__create {
            padding: 0.5rem 1.5rem;
            margin-right: 0.8rem;
            font-weight: bold;
            border-radius: 25px;
            color: #fff;
            border: none;
            background: #59d053;
        }
        &__close {
            // padding: .5rem 1.5rem;
            font-weight: bold;
            border-radius: 25px;
            color: #d76c6d;
            border: none;
            background: none;
        }
    }

    .feedback {
        &__textarea {
            padding: 0.8rem 0.5rem;
            margin-bottom: 0.5rem;
            border: 1px solid #eaf0f4;
            max-height: 120px;
            min-height: 120px;
            border-radius: 8px;
            // box-shadow: inset 1px 1px 2px -10px rgba(#eaf0f4, 0.1);
        }
        &__label {
            color: #b3b3b3;
            font-size: 14px;
        }
    }
}
