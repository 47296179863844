.discount {
    &__ribbon-wrapper {
        h3 {
            font-size: 24px;
            font-weight: 700;
            color: #3b454b;

            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }
        }
    }

    &__ribbon {
        position: relative;
        max-width: 540px;
    }

    &__ribbon-bar {
        position: absolute;
        width: 100%;
        height: 8px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #eaf0f4;
        border-radius: 4px;
        z-index: -1;
        overflow: hidden;

        @include media-breakpoint-down(xs) {
            width: 8px;
            height: 100%;
            left: 18px;
        }
    }

    &__ribbon-inner {
        height: 8px;
        background-color: #78cd62;
    }

    &__badge-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__badge {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 44px;
        width: 44px;

        @include media-breakpoint-down(xs) {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &.active {
            .discount__tag {
                width: 44px;
                height: 44px;

                span {
                    opacity: 1;
                }
            }
        }

        &::after {
            content: attr(data-title);
            position: absolute;
            display: block;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            text-transform: uppercase;
            font-size: 14px;
            color: #3b454b;
            font-weight: 700;
            white-space: nowrap;

            @include media-breakpoint-down(xs) {
                bottom: initial;
                left: 70px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &:nth-child(1) {
            .discount__tag {
                background-color: #fff;
                box-shadow: inset 0 0 0 5px #eaf0f4;

                span {
                    color: #3b454b;
                }
            }
        }

        &:nth-child(2) {
            .discount__tag {
                background-color: #2d4eed;
            }
        }

        &:nth-child(3) {
            .discount__tag {
                background-color: #c773fc;
            }
        }

        &:nth-child(4) {
            .discount__tag {
                background-color: #ee5ca1;
            }
        }

        &:nth-child(5) {
            .discount__tag {
                background-color: rgb(23, 167, 23);
            }
        }

        &:nth-child(6) {
            .discount__tag {
                background-color: lightsalmon;
            }
        }

        &:nth-child(7) {
            .discount__tag {
                background-color: lightseagreen;
            }
        }

        &:nth-child(8) {
        }

        &:nth-child(9) {
        }

        &:last-child {
            .discount__tag {
                background-color: #f5ae24;
            }
        }
    }

    &__tag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 4px solid #ffffff;
        transition: 0.25s;
        cursor: default;

        span {
            opacity: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            transition: 0.25s;

            @include media-breakpoint-down(xs) {
                font-size: 15px;
            }
        }

        &:hover {
            width: 44px;
            height: 44px;

            span {
                opacity: 1;
            }
        }
    }
}
