.revision-view {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
    width: 100vw;
    max-width: 888px;
    overflow: hidden;
    padding: 0 90px 35px;

    @include media-breakpoint-down(lg) {
        padding: 0 40px 35px;
        max-width: 688px;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 20px 35px;
    }

    &.requested,
    &.in-progress,
    &.disputed,
    &.rejected,
    &.graded,
    &.verifying {
        $modal-color: #7ac8ed;

        .modal {
            &__header {
                background-color: $modal-color;
            }

            &__list.check {
                .modal__icon-box {
                    border: 4px solid $modal-color;

                    svg {
                        fill: $modal-color;
                    }
                }
            }

            &__level {
                &::before {
                    border: 4px solid $modal-color;
                    background-color: $modal-color;
                }
            }
        }
    }

    &.completed,
    &.closed {
        $modal-color: #78cd62;

        .modal {
            &__header {
                background-color: $modal-color;
            }

            &__list.check {
                .modal__icon-box {
                    border: 4px solid $modal-color;

                    svg {
                        fill: $modal-color;
                    }
                }
            }

            &__level {
                &::before {
                    border: 4px solid $modal-color;
                    background-color: $modal-color;
                }
            }
        }
    }

    &.canceled,
    &.failed,
    &.expired,
    &.undelivered {
        $modal-color: #d76c6d;

        .modal {
            &__header {
                background-color: $modal-color;
            }

            &__list.check {
                .modal__icon-box {
                    border: 4px solid $modal-color;

                    svg {
                        fill: $modal-color;
                    }
                }
            }

            &__level {
                &::before {
                    border: 4px solid $modal-color;
                    background-color: $modal-color;
                }
            }
        }
    }

    .modal {
        &__header {
            display: flex;
            align-items: center;
            height: 43px;
            color: #fff;
            padding: 5px 17px;
            font-weight: 600;
            margin: 0 -90px 33px;

            @include media-breakpoint-down(lg) {
                margin: 0 -40px 33px;
            }

            @include media-breakpoint-down(sm) {
                margin: 0 -20px 33px;
            }
        }

        &__deadline {
            color: #3b454b;
            font-weight: 700;
        }

        &__title {
            font-size: 31px;
            font-weight: 700;
            color: #3b454b;
        }

        &__label {
            display: inline-block;
            color: #9bacb6;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;

            &.check {
                .modal__list-item {
                    display: flex;
                    align-items: center;
                }

                .modal__icon-box {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    border-radius: 8px;
                    background-color: #ffffff;
                    margin-right: 15px;

                    svg {
                        width: 21px;
                        height: 16px;
                    }
                }
            }
        }

        &__list-item {
            position: relative;
            flex-basis: 50%;
            font-size: 18px;
            color: #3b454b;
            font-weight: 700;
            margin-bottom: 12px;

            @include media-breakpoint-down(xs) {
                flex-basis: 100%;
            }
        }

        &__description {
            border-radius: 8px;
            background-color: #f7f9fa;
            padding: 20px 25px;
            color: #3b454b;
            font-weight: 700;
            max-height: 250px;
            overflow: auto;
            @include customize-scrollbars(5px, var(--main-color), #fbfbfb, visible);

            @include media-breakpoint-down(xs) {
                max-height: 200px;
            }
        }

        &__files {
            display: grid;
            grid-gap: 0 15px;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        &__file {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #3b454b;
            font-weight: 700;
            margin-bottom: 15px;

            svg {
                width: 16px;
                height: 16px;
                fill: #7f91f3;
                margin-right: 5px;
            }
        }

        &__file-name {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
        }

        &__level {
            position: relative;
            padding-left: 45px;
            margin-bottom: 25px;
            font-size: 18px;
            color: #3b454b;
            font-weight: 700;

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 32px;
                height: 32px;
                left: 0;
                border-radius: 50%;
                box-shadow: inset 0 0 0 4px #ffffff;
            }
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 2px solid #eaf0f4;
            font-size: 14px;
            padding-top: 18px;
        }

        &__created {
            color: #9bacb6;
        }

        &__close {
            border: none;
            outline: none;
            background-color: transparent;
            box-shadow: none;
            color: #d76c6d;
            font-weight: 700;
        }
    }
}
