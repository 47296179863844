.search {
    &__wrapper {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 320px;
        height: 36px;
        margin-left: auto;
        background-color: #f8f9fb;
        border-radius: 3px;

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            max-width: 50%;
            order: 7;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        @media screen and (max-width: 440px) {
            margin-top: 40px;
        }

        &:hover {
            .search__icon {
                fill: var(--main-color);
            }
        }
    }

    &__label {
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        font-size: 11px;
        font-weight: 500;
        line-height: 12px;
        color: #9bacb6;
        z-index: -1;
    }

    &__input {
        border: none;
        outline: none;
        height: 100%;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px rgb(0 13 93 / 2%);
        -webkit-appearance: none;
        padding-left: 10px;
        z-index: 0;

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }

        &::placeholder {
            color: #9bacb6;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: none;
        background-color: transparent;
        padding: 5px 8px;
    }

    &__icon {
        width: 22px;
        height: 22px;
        fill: #9bacb6;
        transition: $transition;

        @include media-breakpoint-down(xs) {
            width: 20px;
            height: 20px;
        }
    }
}
