.control-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
        justify-content: space-between;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(sm) {
        justify-content: start;
    }

    .hidden-filter {
        display: flex;
        align-items: center;
        margin-right: 15px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0;

        @include media-breakpoint-down(xs) {
            margin-right: 10px;
        }

        &.show-hidden {
            svg {
                fill: var(--main-color);
            }
        }

        &:hover {
            svg {
                fill: var(--main-color);
            }
        }

        svg {
            width: 25px;
            height: 25px;
            fill: #9bacb6;
            transition: $transition;
            cursor: pointer;

            @include media-breakpoint-down(xs) {
                width: 20px;
                height: 20px;
            }
        }
    }

    .clear-btn {
        position: relative;
        margin-left: 25px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        transition: $transition;
        color: #9bacb6;
        transform: translateX(-120px);

        @include media-breakpoint-down(lg) {
            margin-left: 15px;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 13px;
            margin-left: auto;
            transform: translateX(-195px);
        }

        @include media-breakpoint-down(xs) {
            font-size: 15px;
        }

        @media screen and (max-width: 450px) {
            margin-top: 35px;
        }

        &.active {
            color: #d76c6d;
            transform: translateX(0);
        }
    }

    .divider {
        width: 2px;
        height: 25px;
        background-color: #eaf0f4;
        margin: 0 17px;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        // @include media-breakpoint-down(md) {
        //     display: none;
        // }
    }

    .view-control {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
