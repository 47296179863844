.pagination {
    display: flex;
    justify-content: center;
    margin-top: 25px;

    &__list {
        display: flex;

        li {
            color: #9bacb6;
            min-width: 30px;
            text-align: center;
        }
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        height: 30px;
        padding: 0 5px;
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 18px;
        color: #9bacb6;
        font-weight: 700;
        transition: $transition;

        &.active,
        &:hover {
            color: var(--main-color);
        }
    }

    &__start,
    &__end {
        &:hover {
            svg {
                fill: var(--main-color);
            }
        }

        &.disabled {
            svg {
                fill: #e1e6e8;
            }
        }

        svg {
            fill: #9bacb6;
            transition: $transition;
        }
    }
}
