// TODO:
//  1. All colors in rgb instead hex
//  2. Create scoped css variables for UUID site


a:link:not([class]) {
    color: cl(main);
}

.ant-btn {
    &.ant-btn-primary {
        background-color: cl(main);
        border-color: cl(main);
        box-shadow: 0 4px 8px cl(main, .5);

        &:hover, &:focus {
            filter: brightness(1.2);
        }
    }
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 4px 20px -3px cl(main, .5);
}

// Test
.ant-message-notice {
    .anticon {
        color: cl(main);
    }

    .ant-message-error {
        .anticon {
            color: $danger;
        }
    }
}

