@import "../styles/variables";

// Layout
.wrapper {
    user-select: none;
    background: linear-gradient(45deg, #8644d614, #23dfff1f);
    max-width: 1080px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    justify-content: center;
    grid-gap: 60px;
    gap: 60px;
    margin: 10vh auto;
    padding: 3.5rem 3rem;
    width: 85%;

    @include media-breakpoint-down(md) {
        flex-flow: column;
        padding: 3rem 1.5rem;
        gap: 15px;
    }

    @include media-breakpoint-down(xs) {
        position: relative;
        width: 100%;
    }
}


.left {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100px;
        height: 100px;
        background: #2800ff;
        top: -20px;
        left: -20px;
        border-radius: 50%;
        z-index: -1;
        filter: blur(90px);
    }
}

.right {
    font-size: 1.1rem
}


.footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    @include media-breakpoint-down(sm) {
        gap: 10px;
    }
}

// Content
.heading {
    font-size: 2.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;

    //noinspection ALL
    span {
        background-image: linear-gradient(90deg, #f52e9e, #2d9bf4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
    }


    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
        gap: 0;
        flex-flow: column;
        margin-top: -30px;
    }
}

.text {
    font-size: 1.3rem;
    margin-bottom: 0;
}

.alert {
    font-size: .9rem;
    margin: 1rem 0;
    max-width: 300px;
}

// Elements
.popper {
    //filter: drop-shadow(0 4px 10px rgba(255, 111, 0, .3));
    width: 96px;
    height: 96px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
}

.hello {
    filter: drop-shadow(0 4px 10px rgba(255, 111, 0, .3));
    width: 48px;
    height: 48px;
    transition: all .15s ease;
    transform-origin: 100% 100%;
    will-change: transform;

    :global {
        animation: handWave .2s ease 1s 4;
    }
}

.skeleton {
    color: cl(main, .9);
    box-shadow: 0 15px 22px -15px #05227730, 0 5px 5px -2px #05227710;
    border-radius: 8px;
    max-width: 100%;
    width: 320px;
    z-index: 5;

    @include media-breakpoint-down(md) {
        margin-top: -120px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: -60px;
    }


}

.redirect {
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center
}
