.view-control {
    width: 56px;
    display: flex;
    justify-content: space-between;
    line-height: 20px;

    input:checked+.grid__label,
    input:checked+.list__label {
        .grid__icon {
            fill: var(--main-color);
        }
    }

    label {
        margin-bottom: 0;
    }
}

.grid {
    display: flex;
    align-items: center;

    &__icon {
        width: 24px;
        height: 24px;
        fill: #9bacb6;
        cursor: pointer;
        transition: $transition;
    }

    &__label {
        padding: 0 !important;
        height: initial !important;
        line-height: initial !important;

        &::before,
        &::after {
            display: none !important;
        }
    }
}

.list {
    display: flex;
    align-items: center;

    &__icon {
        @extend .grid__icon;
        height: 20px;
    }

    &__label {
        padding: 0 !important;
        height: initial !important;
        line-height: initial !important;

        &::before,
        &::after {
            display: none !important;
        }
    }
}
