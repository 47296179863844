.details__table {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .table {
        &__item {
            padding-right: 40px;
            width: 29%;
            margin-bottom: 8px;
            word-break: break-word;

            @include media-breakpoint-down(lg) {
                width: 33%;
            }

            @include media-breakpoint-down(md) {
                width: 50%;
            }

            @include media-breakpoint-down(xs) {
                width: 100%;

                &:not(:last-child) {
                    border-bottom: 2px solid #eaf0f4;
                }
            }

            &.full {
                width: 100%;

                .table__text {
                    font-weight: normal;
                    font-style: italic;
                    font-size: 17px;
                }
            }
        }

        &__review {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .feedback-rating {
                svg {
                    &.empty {
                        fill: #dcdcdc;
                    }

                    &.full {
                        fill: #f3c539;
                    }
                }
            }
        }

        &__label {
            font-size: 14px;
            color: #9bacb6;
            font-weight: 700;
            margin-bottom: 4px;
        }

        &__text {
            font-size: 18px;
            color: #3b454b;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 8px;
        }
    }
}