.navbar {
    $grey-color: #e1e6e8;

    &__list {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
    }

    &__item {
        text-align: center;
        width: 120px;

        span {
            display: block;
        }

        &:not(:first-child) {
            .navbar__link {
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 115px;
                    height: 2px;
                    background-color: $grey-color;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        color: #fff;
        margin: 0 auto 10px;

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            width: 16px;
            height: 16px;
            background-color: $grey-color;
            border-radius: 50%;
            transition: $transition;
            z-index: 1;
        }

        &:hover {
            color: #fff;

            &::before {
                background-color: var(--main-color);
            }
        }

        &.visited {
            &::before {
                background-color: var(--main-color);
            }
        }

        &.is-link-active {
            // disabling 'is-link-active' styles
            pointer-events: auto;
            opacity: 1 !important;
        }

        &.active {
            font-size: 16px;

            &::before {
                content: attr(data-link-number);
                background-color: var(--main-color);
                width: 24px;
                height: 24px;
            }
        }
    }

    &__info-text {
        font-size: 13px;
        color: #3b454b;
        font-weight: 700;
        line-height: 16px;
        min-height: 35px;
    }
}
