.profile {
    &__wrapper {
        max-width: 800px;
    }

    &__email,
    &__phone,
    &__name {
        &-group {
            margin-bottom: 18px;
        }

        &-label {
            display: inline-block;
            color: #9bacb6;
            font-size: 14px;
            font-weight: 600;
            border-bottom: 2px dashed #9bacb6;
            margin-bottom: 10px;

            button {
                color: var(--main-color);
                border: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                font-weight: 600;

                &.confirmed {
                    color: #59d053;
                    cursor: default;
                }

                &.await {
                    color: #2d4eed;
                    cursor: default;
                }
            }
        }

        &-value {
            display: flex;
            align-items: center;
            height: 52px;
            border-radius: 8px;
            border: 4px solid #f7f9fa;
            background-color: #f7f9fa;
            font-size: 22px;
            font-weight: 600;
            color: #3b454b;
            padding-left: 22px;
            transition: $transition;

            @include media-breakpoint-down(xs) {
                font-size: 15px;
            }

            &::placeholder {
                color: #9bacb6;
            }
        }
    }

    &__phone,
    &__name {
        &-group {
        }

        &-label {
        }

        &-value {
            background-color: transparent;

            &:hover,
            &:active {
                border-color: var(--main-color);
            }

            &:focus {
                box-shadow: 0 10px 25px cl(main, .40);
                border: 4px solid var(--main-color) !important;
            }

            &.done {
                color: var(--main-color);
                border-color: var(--main-color);
            }
        }
    }

    &__name {
        &-group {
            margin-bottom: 35px;
        }
    }

    &__checkbox-group {
        margin-bottom: 35px;
    }

    &__privacy-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--main-color);
        width: 258px;
        height: 45px;
        margin-bottom: 35px;
        border-radius: 23px;
        border: 4px solid #e1e6e8;
        font-size: 18px;
        font-weight: 600;
        transition: $transition;
        box-shadow: none;
        background-color: transparent;

        @media screen and (max-width: 350px) {
            width: 100%;
        }

        &:hover {
            background-color: var(--main-color);
            border-color: var(--main-color);
            opacity: 0.7;
            color: #fff;
        }
    }

    &__footer {
        border-top: 2px solid #eaf0f4;
        padding-top: 25px;
    }

    &__update-btn {
        border: none;
        box-shadow: none;
        border-radius: 23px;
        background-color: #f7f9fa;
        height: 45px;
        width: 258px;
        color: #aeaeae;
        font-size: 18px;
        font-weight: 600;
        transition: $transition;
        pointer-events: none;

        @media screen and (max-width: 350px) {
            width: 100%;
        }

        &.active {
            color: #fff;
            background-color: var(--main-color);
            pointer-events: all;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .checkbox {
        &__label {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding-left: 0;
            height: initial;
            color: #3b454b;

            &:before {
                display: none;
            }

            span {
                font-size: 22px;
                font-weight: 600;

                @include media-breakpoint-down(sm) {
                    line-height: 25px;
                    font-size: 19px;
                    font-weight: 500;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }
            }

            &:hover {
                .checkbox__button {
                    border-color: var(--main-color);
                }
            }
        }

        &__button {
            display: flex;
            justify-content: center;
            align-items: center;
            order: -1;
            min-width: 46px;
            height: 46px;
            border: 4px solid #e1e6e8;
            background-color: #ffffff;
            border-radius: 8px;
            margin-right: 15px;
            transition: $transition;

            @include media-breakpoint-down(xs) {
                min-width: 40px;
                height: 40px;
            }

            svg {
                fill: #fff;
                transition: $transition;
            }
        }

        &__link {
            color: #2d4eed;
            font-weight: 600;
            transition: $transition;

            &:hover {
                color: lighten(#2d4eed, 5%);
            }
        }

        &__dashed {
            border-bottom: 2px dashed #9bacb6;
        }
    }

    .checkbox__input:checked + .checkbox__label {
        .checkbox__button {
            border-color: var(--main-color);

            svg {
                fill: var(--main-color);
            }
        }
    }

    .checkbox__input:focus + .checkbox__label {
        .checkbox__button {
            box-shadow: 0 10px 25px cl(main, .65) !important;
        }
    }

    .password {
        background-color: #f7f9fa;
        border-radius: 8px;
        margin-bottom: 35px;

        &__field {
            margin-bottom: 23px;

            input {
                background-color: #fff;
            }
        }

        &__label {
            color: #9bacb6;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;

            @media screen and (max-width: 380px) {
                margin-bottom: 5px;
            }
        }

        &__value {
            border: none;
            height: 52px;
            border-radius: 8px;
            padding: 20px 25px;
            font-size: 22px;

            @include media-breakpoint-down(xs) {
                font-size: 15px;
            }

            &::placeholder {
                color: #9bacb6;
                font-size: 22px;
                font-weight: 600;

                @include media-breakpoint-down(xs) {
                    font-size: 15px;
                }
            }
        }

        &__save-btn {
            border: none;
            box-shadow: none;
            background-color: transparent;
            font-size: 18px;
            font-weight: 600;
            padding: 0;
            pointer-events: none;
            color: lighten(#3b454b, 25);

            &.active {
                pointer-events: all;
                color: var(--main-color);

                &:hover {
                    opacity: 0.9;
                }
            }
        }

        .panel {
            &__forgot-btn {
                position: absolute;
                top: 0;
                right: 25px;
                border: none;
                box-shadow: none;
                background-color: transparent;
                padding: 0;
                font-size: 14px;
                color: #9bacb6;
                transition: $transition;
                font-weight: 600;

                @media screen and (max-width: 380px) {
                    position: static;
                    margin-bottom: 15px;
                }

                &:hover {
                    color: lighten(#9bacb6, 8);
                }
            }

            &__head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #3b454b;
                font-size: 22px;
                font-weight: 600;
                padding: 15px 25px;
                height: 52px;
                cursor: pointer;
                transition: $transition;

                @include media-breakpoint-down(xs) {
                    font-size: 19px;
                    font-weight: 500;
                    padding: 0 20px;
                }

                &:hover {
                    color: var(--main-color);

                    svg {
                        fill: var(--main-color);
                    }
                }

                &.opened {
                    color: var(--main-color);

                    svg {
                        fill: var(--main-color);
                        transform: rotate(180deg);
                    }
                }

                svg {
                    width: 20px;
                    height: 13px;
                    transition: $transition;
                }
            }

            &__body {
                position: relative;
                height: 405px;
                padding: 0 25px;
                transition: $transition;
                overflow: hidden;

                @media screen and (max-width: 380px) {
                    height: 415px;
                    padding: 0 20px;
                }

                &.collapsed {
                    height: 0;
                }
            }
        }
    }
}

@import "./privacy";
