.order-messenger {
    @include media-breakpoint-down(sm) {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1100;
        background-color: #fff;
        transition: transform 0.35s;
    }

    &__disabled {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 350px;
        text-align: center;

        svg {
            width: 95px;
            height: 95px;
            margin-bottom: 15px;

            @include media-breakpoint-down(xs) {
                width: 80px;
                height: 80px;
            }
        }

        h3 {
            color: #dd4545;
            font-size: 18px;
        }

        p {
            color: #9bacb6;

            // font-size: 35px;
            @include media-breakpoint-down(xs) {
                // font-size: 24px;
            }
        }

        button {
            background: none;
            box-shadow: none;
            padding: 4px 22px;
            border: 1px solid black;
            border-radius: 23px;
            transition: $transition;

            &:hover {
                box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        min-height: 430px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
        background-color: #fafafa;
        padding: 20px 7px 20px 16px;

        @include media-breakpoint-down(sm) {
            flex-grow: 1;
            border-radius: 0;
            min-height: 200px;
            border: none;
            padding: 0;
            max-height: calc(100% - 56px);
        }
    }

    &__bar {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 9px;

        @include media-breakpoint-down(sm) {
            position: relative;
            height: 56px;
            min-height: 56px;
            margin-bottom: 0;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px;
            background-color: #fff;
            box-shadow: 0 2px 7px rgba(195, 208, 215, 0.35);
            z-index: 1;
        }

        @include media-breakpoint-down(xs) {
            padding: 3px;
            justify-content: space-between;
        }
    }

    &__back,
    &__info-btn {
        display: none;

        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 10px 0 0;
            padding: 0;
            width: 36px;
            height: 36px;
            border: none;
            background-color: #fff;
            box-shadow: none;
        }

        @include media-breakpoint-down(xs) {
            margin: 0;
        }
    }

    &__back {
        svg {
            width: 11px;
            height: 19px;
        }
    }

    &__info-btn {
        @include media-breakpoint-only(sm) {
            display: none;
        }

        @include media-breakpoint-down(xs) {
            visibility: hidden;
        }

        svg {
            width: 22px;
            height: 22px;
            fill: #9bacb6;
        }
    }

    &__info-panel {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 2000;
        transform: translateX(100%);
        transition: transform 0.35s;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            pointer-events: none;
            top: 0;
            left: 0;
            transform: translateX(-100%);
            transition: opacity 0.35s;
            background-color: #000;
            opacity: 0;
        }

        &.slideIn {
            transform: translateX(0);

            &::before {
                opacity: 0.5;
            }
        }

        .info-panel {
            &__wrapper {
                position: relative;
                height: 100%;
            }

            &__close {
                position: absolute;
                top: 5px;
                right: 5px;

                svg {
                    transform: rotate(45deg);
                    width: 22px;
                    height: 22px;
                    min-width: 22px;
                    min-height: 22px;
                    fill: #9bacb6;
                }
            }
        }
    }

    &__toggler {
        &.disabled {
            .toggler__label {
                span {
                    cursor: default;
                }

                .writer-lable::before {
                    cursor: default;
                }
            }
        }

        .toggler {
            &__group {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 0 13px;
                width: 300px;
                height: 41px;
                background: #fafafa;
                border-radius: 9px;
                box-shadow: inset 1px 1px 3px rgba(156, 184, 201, 0.4);

                @include media-breakpoint-down(xs) {
                    height: 36px;
                    width: 240px;
                }

                .badge {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    width: 18px;
                    height: 16px;
                    color: #fff;
                    background-color: #df6060;
                    box-shadow: 0 4px 4px rgba(204, 47, 49, 0.2);
                    border-radius: 4px;
                    font-size: 11px;
                    visibility: hidden;
                    margin: 0 14px 0 0;
                    transition: $transition;

                    @include media-breakpoint-down(xs) {
                        margin: 0;
                    }

                    &:not(:empty) {
                        visibility: visible;
                    }
                }
            }

            &__label {
                display: flex;
                align-items: center;

                span {
                    display: inline-flex;
                    height: 32px;
                    align-items: center;
                    font-size: 15px;
                    color: #9bacb6;
                    font-weight: 600;
                    margin-left: 14px;
                    padding: 0 7px 0 0;
                    cursor: pointer;
                    transition: $transition;

                    @include media-breakpoint-down(xs) {
                        font-size: 13px;
                        margin-left: 0;
                    }
                }

                .support-lable,
                .writer-lable {
                    &::before {
                        content: "";
                        display: inline-block;
                        border-radius: 50%;
                        background-color: #d8e1e7;
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07);
                        width: 8px;
                        height: 8px;
                        margin-right: 7px;
                        margin-bottom: 2px;
                        transition: $transition;
                        cursor: pointer;

                        @include media-breakpoint-down(xs) {
                            margin-bottom: 0;
                        }
                    }

                    &.online::before {
                        background: #78cd62;
                        box-shadow: 0 4px 4px rgba(112, 199, 78, 0.2);
                    }

                    @include media-breakpoint-down(xs) {
                    }
                }

                .writer-lable {
                }

                .locked {
                    margin-left: 0;
                    cursor: pointer !important;

                    svg {
                        width: 12px;
                        height: 16px;
                        min-width: 12px;
                        min-height: 16px;
                        margin-bottom: 4px;
                    }
                }
            }

            &__btn {
                position: absolute;
                top: 50%;
                left: 4px;
                background-color: #ffffff;
                box-shadow: 0 2px 4px rgba(195, 208, 215, 0.55);
                border-radius: 7px;
                width: 150px;
                height: 34px;
                transform: translateY(-50%);
                transition: $transition;
                z-index: 1;

                @include media-breakpoint-down(xs) {
                    width: 122px;
                    height: 30px;
                    left: 4px;
                }

                &.online {
                    .status {
                        background-color: #78cd62;
                        box-shadow: 0 4px 4px rgba(112, 199, 78, 0.2);
                    }
                }

                .status {
                    position: absolute;
                    display: block;
                    border-radius: 50%;
                    background-color: #d8e1e7;
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07);
                    top: 13px;
                    left: 30px;
                    width: 8px;
                    height: 8px;
                    cursor: pointer;
                    z-index: 1;

                    @include media-breakpoint-down(xs) {
                        top: 11px;
                        left: 18px;
                    }
                }

                span {
                    position: absolute;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    font-size: 16px;
                    font-weight: 700;
                    color: #7ac8ed;

                    @include media-breakpoint-down(xs) {
                        font-size: 15px;
                    }
                }

                .support {
                    opacity: 1;
                }

                .writer {
                    opacity: 0;
                }

                .badge {
                    position: absolute;
                    left: initial;
                    right: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-right: 0 !important;
                    transition: none !important;

                    &:not(:empty) {
                        transition: $transition 0.1s !important;
                    }

                    @include media-breakpoint-down(xs) {
                        right: 10px;
                    }
                }
            }
        }
    }

    &__chat-wrapper {
        position: relative;
        margin-top: auto;
        display: flex;

        @include media-breakpoint-down(sm) {
            overflow: hidden;
        }

        &.empty {
            flex-grow: 1;
            margin-top: 0;
        }
    }

    &__chat {
        position: relative;
        display: none;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 15px;
        padding: 5px 23px 8px 14px;
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
        @include customize-scrollbars(6px, #9bacb6, #ffffff, visible, $border-radius: 7px);

        @include media-breakpoint-down(sm) {
            max-height: 100%;
            padding: 15px 15px 10px;
            @include customize-scrollbars(4px, #9bacb6, #ffffff, visible, $border-radius: 7px);
            margin-bottom: 0;
            min-height: initial;
        }

        &.visible {
            display: flex;
        }

        .chat {
            &__row {
                &:not(:first-child) {
                    margin-top: 10px;

                    @include media-breakpoint-down(xs) {
                        margin-top: 8px;
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 10px;

                    @include media-breakpoint-down(xs) {
                        margin-bottom: 8px;
                    }
                }

                &.customer {
                }
            }

            &__message {
                display: flex;

                &.customer {
                    flex-direction: row-reverse;

                    .message__text {
                        border-radius: 10px 10px 0 10px;
                        background-color: #4cacf6;
                        color: #fff;

                        a {
                            text-decoration: underline;
                            color: #ffffff;
                        }

                        .message__file-list {
                            .file {
                                &__item {
                                    color: #ffffff;

                                    svg {
                                        fill: #ffffff;
                                    }
                                }
                            }
                        }
                    }

                    .message__timestamp {
                        margin-left: auto;
                    }
                }

                .message {
                    &__body {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        max-width: 635px;

                        @include media-breakpoint-down(md) {
                            max-width: 550px;
                        }

                        @include media-breakpoint-down(sm) {
                            max-width: 77%;
                        }

                        &.plain {
                            min-width: initial;
                            box-shadow: none;
                            background-color: transparent;
                        }

                        &.deleted {
                            .message__text {
                                background-color: rgba(223, 96, 96, 0.5);
                            }
                        }

                        &.plain.deleted {
                            opacity: 0.3;
                        }
                    }

                    &__text {
                        line-height: 22px;
                        color: #3b454b;
                        white-space: pre-line;
                        background-color: #fff;
                        box-shadow: 0 2px 7px rgba(195, 208, 215, 0.35);
                        border-radius: 10px 10px 10px 0;
                        padding: 15px 15px 12px;
                        word-break: break-word;

                        @include media-breakpoint-down(xs) {
                            font-size: 13px;
                            line-height: 18px;
                            padding: 7px 10px 6px;
                        }

                        a {
                            text-decoration: underline;
                            color: #4cacf6;
                        }

                        p {
                            margin-bottom: 0;
                        }

                        ul,
                        ol {
                            padding-left: 30px;
                        }

                        ul {
                            list-style-type: disc;

                            li {
                                list-style-type: disc;
                            }
                        }

                        em {
                            font-style: italic !important;
                        }

                        .message__file-list {
                            margin-bottom: 3px;
                            padding-left: 0;
                            list-style: none;

                            .file {
                                &__item {
                                    display: flex;
                                    align-items: center;

                                    &:not(:first-child) {
                                        margin-top: 10px;
                                    }

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                        min-width: 20px;
                                        fill: #3b454b;
                                        margin-right: 8px;
                                    }

                                    a {
                                        color: inherit;
                                        text-decoration: none;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;

                                        @include media-breakpoint-down(xs) {
                                            max-width: 235px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &__sticker {
                        width: 64px;
                        height: 64px;
                    }

                    &__emoji {
                        font-size: 50px;
                        line-height: 1;
                        padding-left: 7px;
                    }

                    &__timestamp {
                        font-size: 10px;
                        color: #9bacb6;
                        margin: 8px 0 0;
                        line-height: 1;

                        @include media-breakpoint-down(xs) {
                            font-size: 10px;
                            margin: 6px 0 0;
                        }
                    }

                    &__status {
                        align-self: center;
                        padding: 0 8px;
                        margin-bottom: 18px;

                        @include media-breakpoint-down(xs) {
                            padding: 0 8px;
                        }

                        svg {
                            fill: rgba(155, 172, 182, 0.5);
                            width: 13px;
                            height: 8px;
                        }

                        &.read {
                            svg {
                                fill: #78cd62;
                            }
                        }

                        &.deleted {
                            svg {
                                fill: #df6060;
                                width: 11px;
                                height: 11px;
                            }
                        }
                    }
                }
            }

            &__divider {
                position: relative;
                display: flex;
                justify-content: center;
                border-bottom: 1px solid #d9e5ec;

                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: -2px;
                    transform: translateY(-50%);
                    background-color: #fff;
                    opacity: 0.5;
                }

                span {
                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: flex-end;
                    color: #ffffff;
                    background-color: #d9e5ec;
                    font-size: 11px;
                    line-height: 1;
                    width: 44px;
                    height: 15px;
                    border-radius: 2px 2px 0 0;
                }
            }

            &__empty {
                font-size: 24px;
                color: #9bacb6;
                margin: auto;
            }

            // &__spinner {
            //     display: inline-block;
            //     padding: 10px 10px 0 15px;
            //     font-size: 14px;
            //     color: #ccc;
            //     border-radius: 30px;
            //     line-height: 1.25em;
            //     font-weight: 100;
            //     align-self: flex-end;
            //     &-wrapper {
            //         margin: 0;
            //         width: 30px;
            //         text-align: center;
            //         &>div {
            //             width: 10px;
            //             height: 10px;
            //             border-radius: 100%;
            //             display: inline-block;
            //             animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            //             background: #9BACB6;
            //         }
            //         & .bounce1 {
            //             animation-delay: -0.32s;
            //         }
            //         & .bounce2 {
            //             animation-delay: -0.16s;
            //         }
            //     }
            // }
        }
    }

    &__sender {
        position: relative;
        padding-right: 10px;

        @include media-breakpoint-down(sm) {
            background-color: #fcfcfc;
            box-shadow: 0 -1px 3px rgba(195, 208, 215, 0.3);
            padding: 5px 10px 10px;
        }

        .sender {
            &__file-bar {
                display: flex;
                width: calc(100% - 120px);

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                &:not(:empty) {
                    margin-bottom: 6px;
                }
            }

            &__file-list {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -5px;
                width: 100%;
                line-height: 1;

                @include media-breakpoint-down(sm) {
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    @include customize-scrollbars(2px, #9bacb6, #ffffff, visible, $border-radius: 15px);
                    padding-bottom: 2px;
                }

                li {
                    display: flex;
                    align-items: center;
                    max-width: 220px;

                    @include media-breakpoint-down(sm) {
                        // flex-grow: 1;
                    }

                    .file {
                        &__input {
                            display: none;
                        }

                        &__name {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #3b454b;
                            background-color: #ffffff;
                            box-shadow: 0 1px 4px rgba(37, 59, 70, 0.08);
                            border-radius: 7px;
                            padding: 7px 11px;
                            margin: 3px 5px;
                            min-width: 137px;
                            cursor: pointer;
                        }

                        &__delete {
                            position: relative;
                            display: inline-block;
                            width: 10px;
                            min-width: 10px;
                            height: 10px;
                            transform: rotate(45deg);
                            margin-right: 5px;

                            &::before,
                            &::after {
                                content: "";
                                position: absolute;
                                display: block;
                                background-color: #9bacb6;
                            }

                            &::before {
                                top: 50%;
                                left: 0;
                                height: 1px;
                                width: 100%;
                                transform: translateY(-50%);
                            }

                            &::after {
                                top: 0;
                                left: 50%;
                                height: 100%;
                                width: 1px;
                                transform: translateX(-50%);
                            }
                        }

                        &__title {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            display: inline-block;
                            max-width: 145px;

                            @include media-breakpoint-down(sm) {
                                max-width: 65px;
                            }
                        }
                    }
                }
            }

            &__add-btn {
                position: relative;
                display: none;
                justify-content: center;
                align-items: center;
                border: none;
                background-color: #fff;
                box-shadow: 0 1px 4px rgba(37, 59, 70, 0.08);
                border-radius: 7px;
                margin: 3px 10px 3px 0;
                width: 28px;
                height: 28px;

                @include media-breakpoint-down(sm) {
                    display: flex;
                }

                .file-counter {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #70c74e;
                    color: #fff;
                    font-size: 9px;
                    line-height: 1;
                    width: 16px;
                    height: 16px;
                    top: -5px;
                    right: -7px;
                    border-radius: 4px;
                    box-shadow: 0 4px 4px rgba(112, 199, 78, 0.2);
                }

                svg {
                    width: 14px;
                    height: 14px;
                    min-width: 14px;
                    min-height: 14px;
                    fill: #9bacb6;
                }
            }

            &__body {
                display: flex;

                @include media-breakpoint-down(sm) {
                    align-items: flex-end;
                }
            }

            &__text-group {
                display: flex;
                flex-grow: 1;
                background-color: #fff;
                border: 0.5px solid #eaf0f4;
                border-radius: 9px;
                padding: 6px 10px 7px 10px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                    border: none;
                    background-color: transparent;
                }
            }

            &__textarea {
                resize: none;
                @include customize-scrollbars(4px, #9bacb6, #ffffff, visible, $border-radius: 15px);
                border: none;
                font-size: 16px;
                line-height: 22px;
                -webkit-appearance: none;

                &::placeholder {
                    color: #9bacb6;
                }

                @include media-breakpoint-down(sm) {
                    border: 0.5px solid #eaf0f4;
                    border-radius: 9px;
                    padding: 6px 10px;
                    font-size: 15px;
                    height: 38px;
                    transition: $transition;
                    max-height: 86px;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 13px;
                }
            }

            &__menu {
                width: 80px;
                min-width: 80px;
                margin-left: 15px;

                @include media-breakpoint-down(sm) {
                    position: relative;
                    display: flex;
                    align-items: flex-end;
                    width: 36px;
                    min-width: 36px;
                    margin-left: 10px;

                    &.opened {
                        z-index: 1;

                        &::before {
                            content: "";
                            position: absolute;
                            display: block;
                            background-color: rgba(0, 0, 0, 0.5);
                            width: 200vw;
                            height: 200vh;
                            top: -150vh;
                            left: -150vw;
                        }

                        ul {
                            display: flex;
                        }

                        .menu__item.sticker {
                            animation: popUp 0.3s ease 0.05s forwards;
                        }

                        .menu__item.files {
                            animation: popUp 0.3s ease forwards;
                        }

                        .menu__open svg {
                            fill: #fff;
                            transform: rotate(45deg);
                        }
                    }
                }

                ul {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    @include media-breakpoint-down(sm) {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -110%);
                        display: none;
                        flex-direction: column;
                    }
                }

                .menu {
                    &__item {
                        border: none;
                        box-shadow: none;
                        margin: 0;
                        padding: 5px;
                        background-color: transparent;

                        @include media-breakpoint-down(sm) {
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            box-shadow: 0 2px 18px rgba(0, 0, 0, 0.15);
                            background-color: #fff;
                            margin: 5px;
                            opacity: 0;

                            &.emoji {
                                display: none;
                            }
                        }

                        &.active {
                            svg {
                                fill: #2d4eed;
                            }
                        }

                        svg {
                            width: 17px;
                            height: 17px;
                            fill: #9bacb6;
                            transition: $transition;

                            &:hover {
                                fill: #2d4eed;
                            }
                        }
                    }

                    &__panel {
                        position: absolute;
                        width: 295px;
                        height: 97px;
                        background-color: #ffffff;
                        border-radius: 7px;
                        top: -107px;
                        right: 0;
                        padding: 12px 8px;
                        filter: drop-shadow(0 2px 7px rgba(195, 208, 215, 0.35));
                        opacity: 0;
                        transform: translateY(10px);
                        animation: popUp 0.2s ease forwards;

                        &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            border: 10px solid transparent;
                            bottom: -20px;
                            right: 61px;
                            border-top-color: #ffffff;
                        }

                        li {
                            cursor: pointer;
                        }
                    }

                    &__open {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 36px;
                        height: 36px;
                        border: none;
                        background-color: transparent;
                        box-shadow: none;

                        @include media-breakpoint-up(md) {
                            display: none;
                        }

                        svg {
                            width: 22px;
                            height: 22px;
                            min-width: 22px;
                            min-height: 22px;
                            fill: #9bacb6;
                            transition: $transition;
                        }
                    }
                }

                .emoji-panel {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    li {
                        min-width: 20px;
                    }
                }

                .sticker-panel {
                    &::after {
                        right: 30px;
                    }

                    ul {
                        display: flex;
                        width: 100%;
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        overflow-y: hidden;
                        padding-bottom: 2px;
                        @include customize-scrollbars(2px, #9bacb6, #ffffff, visible, $border-radius: 15px);
                    }

                    li {
                        width: 64px;
                        height: 64px;
                        min-width: 64px;
                    }

                    img {
                        width: 64px;
                        height: 64px;
                    }
                }
            }

            &__btn-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 10px;

                @include media-breakpoint-down(sm) {
                    padding-left: 5px;
                    margin-bottom: 1px;
                }

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 110px;
                    height: 40px;
                    border: none;
                    font-weight: 600;
                    box-shadow: 0 2px 7px rgba(76, 172, 246, 0.3);
                    border-radius: 7px;
                    margin-bottom: 10px;
                    background-color: #4cacf6;
                    transition: $transition;

                    &:hover {
                        @include media-breakpoint-up(md) {
                            box-shadow: 0 4px 15px rgba(76, 172, 246, 0.3);
                            background-color: lighten(#4cacf6, 3%);
                        }
                    }

                    &:disabled {
                        opacity: 0.3;
                    }

                    .mobile-icon {
                        display: none;
                    }

                    .text-label {
                        color: #fff;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 36px;
                        height: 36px;
                        padding: 0;
                        margin: 0;
                        border: none;
                        box-shadow: none;
                        background-color: transparent;

                        .text-label {
                            display: none;
                        }

                        .mobile-icon {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            width: 27px;
                            height: 27px;
                            border-radius: 50%;
                            background-color: #7ac8ed;
                            box-shadow: 0 2px 4px rgba(122, 200, 237, 0.2);
                        }

                        svg {
                        }
                    }
                }

                span {
                    font-size: 15px;
                    color: rgba(0, 0, 0, 0.15);

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            &__sticker-pallet {
                ul {
                    display: flex;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    @include customize-scrollbars(2px, #9bacb6, #ffffff, visible, $border-radius: 15px);
                    margin-top: 5px;
                }

                img {
                    max-width: 64px;
                    height: 64px;
                }
            }

            &__char-counter {
                position: absolute;
                left: 10px;
                bottom: -19px;
                font-size: 12px;
                color: #9bacb6;

                @include media-breakpoint-down(sm) {
                    bottom: 80px;
                    left: initial;
                    right: 35px;
                    font-size: 11px;
                }
            }
        }
    }

    &__unavailable {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        height: 95px;
        color: #9bacb6;
        border: 0.5px solid #eaf0f4;
        border-radius: 9px;
        margin-right: 9px;

        @include media-breakpoint-down(sm) {
            height: 52px;
            font-size: 14px;
            background: #fcfcfc;
            box-shadow: 0 -1px 3px rgba(195, 208, 215, 0.3);
            border: none;
            border-radius: 0;
            margin: 0;
        }
    }

    &__placeholder {
        @include media-breakpoint-down(sm) {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .placeholder {
            &__wrapper {
                height: 100%;

                @include media-breakpoint-down(sm) {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__toggler {
                box-shadow: inset 1px 1px 3px rgba(156, 184, 201, 0.4);
                border-radius: 9px;
                width: 258px;
                height: 38px;
                margin-bottom: 10px;
                background-color: #f4f4f4;

                @include media-breakpoint-down(sm) {
                    margin: 10px 0 10px 10px;
                }

                @include media-breakpoint-down(xs) {
                    margin: 10px auto;
                    width: 240px;
                    height: 36px;
                }
            }

            &__body {
                background: #fafafa;
                box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                padding: 20px 16px;

                @include media-breakpoint-down(sm) {
                    display: flex;
                    flex-direction: column;
                    border-radius: 0;
                    flex-grow: 1;
                }
            }

            &__chat {
                display: flex;
                flex-direction: column;
                min-height: 300px;

                @include media-breakpoint-down(sm) {
                    flex-grow: 1;
                }

                .msg {
                    width: 200px;
                    height: 50px;
                    margin-bottom: 30px;
                    border-radius: 10px 10px 10px 0;
                    background-color: #f4f4f4;
                    box-shadow: 0 2px 1px rgba(195, 208, 215, 0.35);

                    @include media-breakpoint-down(xs) {
                        height: 46px;
                    }

                    &.right {
                        margin-left: auto;
                        border-radius: 10px 10px 0 10px;
                    }
                }
            }

            &__footer {
                position: relative;
                display: flex;

                @include media-breakpoint-down(sm) {
                    background: #fcfcfc;
                    box-shadow: 0 -1px 3px rgba(195, 208, 215, 0.3);
                    margin: 0 -16px -20px;
                    padding: 8px;
                }
            }

            &__input {
                background: #ffffff;
                border: 0.5px solid #eaf0f4;
                border-radius: 9px;
                height: 95px;
                flex-grow: 1;
                padding: 15px;

                @include media-breakpoint-down(sm) {
                    height: 38px;
                    padding: 8px;
                }

                .text {
                    display: inline-block;
                    height: 15px;
                    width: 200px;
                    margin-right: 10px;
                    background-color: #f4f4f4;
                    border-radius: 5px;
                }

                .mobile-hide {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            &__button {
                width: 110px;
                height: 40px;
                border-radius: 7px;
                margin-left: 10px;
                background-color: #f4f4f4;
                box-shadow: 0 2px 1px rgba(195, 208, 215, 0.35);

                @include media-breakpoint-down(sm) {
                    height: 36px;
                    width: 36px;
                    min-width: 36px;
                    border-radius: 50%;
                }
            }
        }
    }
}

.toggler__input:checked + .toggler__btn {
    left: 146px;

    @include media-breakpoint-down(xs) {
        left: 114px;
    }

    .writer {
        opacity: 1;
    }

    .support {
        opacity: 0;
    }
}

.toggler__input:disabled {
    pointer-events: none;
}

div + .message__file-list {
    margin-top: 10px;
}

.delete-subscription {
    color: #ffffff;
    opacity: 0.5;
}

.chat__spinner {
    display: inline-block;
    padding: 10px 10px 0 15px;
    font-size: 14px;
    color: #ccc;
    border-radius: 30px;
    line-height: 1.25em;
    font-weight: 100;
    align-self: flex-end;

    &-wrapper {
        margin: 0;
        width: 30px;
        text-align: center;

        & > div {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            display: inline-block;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            background: #9bacb6;
        }

        & .bounce1 {
            animation-delay: -0.32s;
        }

        & .bounce2 {
            animation-delay: -0.16s;
        }
    }
}

.chat__typing {
    position: absolute;
    left: 0;
    bottom: 8px;

    @include media-breakpoint-down(sm) {
        left: 10px;
        bottom: 8px;
    }

    &-wrapper {
        display: inline-flex;
        align-items: center;
        background-color: #fff;
        box-shadow: 0 2px 7px rgba(195, 208, 215, 0.35);
        border-radius: 10px;
        padding: 3px 20px 3px 10px;
    }

    .chat__spinner {
        padding: 0;
        opacity: 0.7;

        &-wrapper {
            & > div {
                width: 5px;
                height: 5px;
            }
        }
    }

    .typing-label {
        font-size: 11px;
        color: #9bacb6;
    }
}
