// MagicRedirect styles
.defaultButton {
    white-space: nowrap;
    background: linear-gradient(0deg, #feac3c, #ffd543);
    box-shadow: 0 4px 8px #ffa50061;
    border-radius: 10px;
    padding: .5ch 2ch;
    overflow: visible;
    transition: all .2s ease;
    cursor: pointer;

    &:hover {
        box-shadow: 0 6px 10px #ffa50080;
        filter: brightness(1.1);
    }

    &:active {
        box-shadow: 0 4px 6px #ffa50040;
        transform: translateY(1px);
    }

    &.locked {
        pointer-events: none;
        animation: loading 10s linear infinite;
        box-shadow: 0 4px 10px #ffa50030;
        background-image:
            repeating-linear-gradient(
                    -45deg,
                    #ffd84d,
                    #ffd84d 1rem,
                    #fbd123 1rem,
                    #fbd123 2rem
            );
        background-size: 200% 200%;
    }
}

@keyframes loading {
    100% {
        background-position: 100% 100%;
    }
}
