.counter {
    &__wrapper {
        display: inline-block;
        position: relative;
        width: 100%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &__item {
        height: 52px;
        border-radius: 8px;
        border: 4px solid transparent;
        background-color: $grey-color;
        color: #3b454b;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        transition: 0.25s;

        @include media-breakpoint-down(lg) {
            max-width: initial;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;

        &:focus {
            border-color: var(--main-color);
            border-bottom: 4px solid var(--main-color) !important;
            background-color: #ffffff;
            color: var(--main-color);
            box-shadow: 0 10px 25px cl(main, .40);
        }

        &.focused {
            border-color: var(--main-color);
            border-bottom: 4px solid var(--main-color) !important;
            background-color: #ffffff;
            color: var(--main-color);
        }

        &.error {
            border: 4px solid $error-color;
            background-color: $grey-color;
            color: #3b454b;
            box-shadow: none;

            .select__arrow {
                fill: #3b454b;
            }
        }

        &.is-disabled {
            border: 4px solid $grey-color;
            background-color: $grey-color;
            color: #ccd5db;
            box-shadow: none;
            pointer-events: none;

            .orderform__check-arrow {
                fill: $grey-color;
            }

            .select__arrow {
                fill: $grey-color;
            }
        }
    }

    &__control {
        &-inc {
            right: 5px;
        }

        &-dec {
            left: 5px;
        }

        &-inc,
        &-dec {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            height: 100%;
            width: 53px;
            cursor: pointer;
            background: none;
            border: none;
            transition: 0.2s ease;

            svg {
                path {
                    transition: 0.2s ease;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: var(--main-color);
                    }
                }
            }

            &.disabled {
                opacity: 0.8;
            }
        }
    }

    &__group {
        display: inline-block;
        margin-bottom: 20px;

        &:first-child {
            flex-grow: 1;
            margin-right: 15px;

            @include media-breakpoint-down(sm) {
                margin: 0rem 0;
            }
        }

        &:last-child {
            flex-grow: 1;
            margin-left: 15px;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            width: 100%;
            flex-direction: column;

            &:nth-child(2) {
                order: 1;
                align-items: center;
            }
        }
    }
}
