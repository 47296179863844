.orderinfo {
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }

        .heading {
            margin: 0 36px 0 0;

            @include media-breakpoint-down(sm) {
                margin: 0 36px 10px 0;
            }
        }

        .header-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 290px;
            height: 43px;
            border-radius: 3px;
            background-color: #eaf0f4;
            color: #fff;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }
        }
    }

    &__container {
        min-height: 280px;

        .ordercard__features {
            cursor: default;
        }
    }

    &__feedback {
        display: flex;
        border-radius: 3px;
        background-color: #eaf0f4;
        padding: 15px 25px;
        margin-bottom: 25px;
        color: #3b454b;

        @include media-breakpoint-down(xs) {
            padding: 15px;
        }

        .feedback {
            &__left {}

            &__icon {
                width: 40px;
                height: 40px;
                margin-right: 16px;

                @include media-breakpoint-down(xs) {
                    width: 30px;
                    height: 30px;
                }
            }

            &__title {
                line-height: 18px;
                margin-bottom: 5px;
                font-size: 19px;
                font-weight: 700;

                @include media-breakpoint-down(xs) {
                    line-height: 22px;
                }
            }

            &__text {
                margin-bottom: 8px;
            }

            &__rating {
                svg {
                    &.empty {
                        fill: #dcdcdc;
                    }

                    &.full {
                        fill: #f3c539;
                    }

                    // fill: #f3c539;
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 2px solid #eaf0f4;
        padding-top: 20px;
        margin-top: 20px;
    }

    &__back-link {
        color: #9bacb6;
        font-size: 14px;
        font-weight: 700;
        transition: $transition;

        &:hover {
            color: var(--main-color);
        }
    }

    &__hide {
        color: #d76c6d;
        font-size: 14px;
        font-weight: 700;
        border: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0;

        &.disabled {
            color: #9bacb6;
            pointer-events: none;
        }
    }
}

.unpaid,
.hold {
    .header-badge {
        background-color: #d76c6d;
    }
}

.paid,
.in-progress,
.modified,
.offered {
    .header-badge {
        background-color: #7ac8ed;
    }
}

.completed {
    .header-badge {
        background-color: #78cd62;
    }
}

.canceled,
.refunded {
    .header-badge {
        background-color: #9bacb6;
    }
}

@import 'details/details';
@import 'files/files';
@import 'revisions/revisions';
@import 'messenger/messenger';
@import './ordermessenger/order-messenger';
