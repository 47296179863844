.referrer-discount {
    border: 2px solid #eaf0f4;
    padding: 1rem 1rem 0;
    border-radius: 8px;
    margin-bottom: 15px;
    color: #9bacb6;
    display: flex;

    &.unavailable {
        .referrer-discount__img {
            width: 26px;
        }
    }

    &__wrapper {
        display: flex;
    }
    &__content {
        flex: 1;
    }

    &__code {
        display: inline-flex;
        border: 2px dashed #eaf0f4;
        padding: 0 0.2rem;
    }

    &__img {
        margin-right: 1rem;
        width: 40px;
        height: 40px;
    }

    &__heading {
        margin: 0;
        font-weight: bold;
    }
}

// ------------------------------------------------------

.truncate-wrapper {
    display: flex;
    align-items: center;
    margin-right: 0 !important;
}

.truncate {
    max-width: 640px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(md) {
        max-width: 375px;
    }
    @include media-breakpoint-down(sm) {
        max-width: 215px;
    }
}

.copy-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    box-shadow: none;
    offset: none;
    width: 35px;
    height: 35px;
    background-color: cl(main);
    border-radius: 50%;
    transition: 0.25s;

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(#113e8b, 0.18), 0 4px 15px 0 rgba(#113e8b, 0.15);
    }

    svg {
        width: 18px;
        height: 20px;
        fill: #fff;
    }
}

.placeholder {
    &__ready {
        max-width: 320px;
        height: 37px;
        border-radius: 3px;
        background: #eaf0f4;
        margin-bottom: 25px;

        @include media-breakpoint-down(xs) {
            height: 33px;
        }
    }

    &__link {
        display: inline-block;
        width: 360px;
        height: 28px;
        border-radius: 3px;
        background: cl(main);
        margin-right: 10px;

        @include media-breakpoint-down(sm) {
            width: 215px;
        }
    }
}

@import "discount/code";
@import "discount/ribbon";
@import "referral/link";
@import "referral/list";
@import "referral/welcome";
