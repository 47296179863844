.payment {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(19, 22, 23, 0.15);
    width: 100vw;
    max-width: 500px;
    // overflow: hidden;
    padding: 25px 50px 0;

    @include media-breakpoint-down(xs) {
        padding: 20px 20px 0;
    }

    .modal {
        &__close-btn {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: transparent;
            border: none;
            outline: none;
            box-shadow: none;
        }

        &__title {
            font-size: 25px;
            margin-bottom: 15px;
            font-weight: 700;

            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }
        }

        &__footer {
            margin: 30px -50px 0;
            height: 52px;

            @include media-breakpoint-down(xs) {
                margin: 30px -20px 0;
            }
        }

        &__pay-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: none;
            box-shadow: none;
            border-radius: 0 0 8px 8px;
            background-color: #78cd62;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            transition: $transition;

            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }

            &:hover {
                background-color: lighten(#78cd62, 8);
            }
        }
    }

    .payment-select {
        &__group {
            position: relative;
            width: 100%;
        }

        &__output {
            position: relative;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            height: 75px;
            background-color: #ffffff;
            box-shadow: 0 0 18px -4px var(--main-color);
            border-radius: 4px;
            color: #c8c8ca;
            font-size: 15px;
            cursor: pointer;
            transition: $transition;

            @include media-breakpoint-down(xs) {
                padding: 10px 10px;
            }

            h5 {
                color: var(--main-color);
                font-size: 22px;
                margin-bottom: 0;

                @include media-breakpoint-down(xs) {
                    font-size: 18px;
                }
            }

            span {
                font-size: 14px;

                @include media-breakpoint-down(xs) {
                    display: inline-block;
                    font-size: 13px;
                    line-height: 16px;
                }
            }

            &:hover {
                box-shadow: 0 0 25px 0 var(--main-color);
            }

            &.is-opened {
                box-shadow: 0 0 25px 0 var(--main-color);

                .payment-select__column {
                    &:last-child {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
        }

        &__column {
            &:first-child {
                svg {
                    width: 40px;
                    margin-right: 15px;

                    @include media-breakpoint-down(xs) {
                        width: 30px;
                        margin-right: 10px;
                    }
                }
            }

            &:last-child {
                margin-left: auto;

                svg {
                    width: 20px;
                    height: 13px;
                    fill: var(--main-color);
                    transition: $transition;

                    @include media-breakpoint-down(xs) {
                        width: 18px;
                        height: 11px;
                    }
                }
            }
        }

        &__dropdown {
            position: absolute;
            left: 0;
            top: 85px;
            padding: 10px 10px 10px 16px;
            box-shadow: 0 0 18px -4px var(--main-color);
            background-color: #ffffff;
            border-radius: 4px;
            width: 100%;
            font-size: 15px;
            z-index: 20;
            opacity: 0;
            pointer-events: none;
            transition: $transition;
            margin-bottom: 80px;

            @include media-breakpoint-down(xs) {
                top: 80px;
            }

            &.is-opened {
                opacity: 1;
                pointer-events: all;
            }
        }

        &__option {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #c8c8ca;
            font-weight: 700;
            transition: $transition;

            &:hover {
                color: var(--main-color);
            }

            &.is-highlighted {
                color: var(--main-color);
            }

            &:not(:last-child) {
                margin-bottom: 12px;

                @include media-breakpoint-down(xs) {
                    margin-bottom: 5px;
                }
            }

            &:not(:first-child) {
                padding-top: 12px;
                border-top: 2px solid #c8c8ca;

                @include media-breakpoint-down(xs) {
                    padding-top: 5px;
                }
            }

            .option__left {
                svg {
                    width: 40px;
                    margin-right: 15px;

                    @include media-breakpoint-down(xs) {
                        width: 30px;
                    }
                }
            }

            .option__right {
                .title {
                    font-size: 19px;
                    color: currentColor;

                    @include media-breakpoint-down(xs) {
                        font-size: 16px;
                    }
                }

                span {
                    font-size: 13px;
                    font-weight: normal;
                    color: #c8c8ca;

                    @include media-breakpoint-down(xs) {
                        display: inline-block;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
