$badge-dot-size: 14px;

.BadgeCounter {
    &__wrapper {
        color: #9bacb6;
        font-size: 0.95rem;
        padding: 0 6px;
    }
    &__value {
        color: var(--main-color);
        font-weight: 500;
    }
}

.BadgeDot {
    &__wrapper {
        position: relative;
    }
    &__dot {
        position: absolute;
        top: -4px;
        right: 1px;
        transform: translate(100%, -50%);
        display: flex;
        background: red;
        color: white;
        border-radius: 35px;
        width: $badge-dot-size;
        height: $badge-dot-size;
        align-items: center;
        justify-content: center;
        border: 3px solid white;
        box-sizing: border-box;
        will-change: transform;
    }
}
