.main .login {
    &__wrapper {
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 3rem 2rem;
        background: #fafafa;

        // Fullscreen
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        a, .link {
            color: cl(main, .8);
            cursor: pointer;

            &:hover {
                color: cl(main);
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 1rem 0;
        }
    }

    &__input {
        background-color: #fff;
        color: cl(main);
        display: flex;
        height: 52px;
        border-radius: 12px;
        transition: all 0.25s ease;
        margin-bottom: 0.5rem;
        padding: 0 20px 0 10px;
        width: 270px; // fixed input width (coz: antd)

        &,
        input {
            // also cover case with nested input (coz: antd wrap input with Form.Item)
            // margin-left: 0.5rem;
            font-size: 18px;
            font-weight: 500;

            &::placeholder {
                color: black;
            }
        }

        &,
        &:hover,
        &:focus {
            border: 4px solid cl(main) !important;
            align-items: center;
        }

        &:focus {
            border: 4px solid !important;
        }

        &-suffix {
            position: absolute;
            right: 0;
            padding: 1.2rem 0.75rem;
            top: 0;
            bottom: 0;
            font-size: 1.2rem;
        }
    }

    &__form {
        text-align: center;
        background: #fff;
        padding: 2rem;
        border-radius: 15px;
        box-shadow: 0 1px 2px #292f5c12;

        &-group {
            position: relative;
        }
    }

    &__btn[class] {
        height: unset;
        padding: 0.5rem 3rem;
    }

    &__heading {
        font-size: 2rem;
        font-weight: normal;
        margin-bottom: 1rem;
        text-align: left;
        animation: zoomIn 0.35s ease 0.2s both;
        will-change: transform;

        .anticon {
            // margin-right: 0.5ch;
        }
    }

    &__link {
        display: block;
        margin-top: 1rem;
        color: black;
        font-size: 1rem;
        width: 320px;
        text-align: center;
        line-height: 30px;
    }

    // Animations
    &__form,
    &__input,
    &__btn,
    &__link {
        opacity: 0;
        animation: fadeInUp 0.25s ease both;
    }

    &__input {
        animation-delay: 0.1s;
    }

    &__btn {
        animation-delay: 0.2s;
    }

    &__link {
        animation-delay: 0.4s;
    }

    // ANTD FORMS
    .ant-form {
        .ant-row {
            margin: 0;
        }

        .ant-input {
            border-radius: 0;

            &:focus {
                box-shadow: none !important; // fix
            }
        }

        .ant-form-item-explain {
            opacity: 0.8;
            position: relative; // perfect pixel
            top: -8px; // perfect pixel
        }

        .ant-form-item-children-icon {
            top: 12px; // perfect pixel
            bottom: 0;
            height: 80%; // perfect pixel
            display: flex;
            align-items: center;
        }

        .ant-input-prefix:empty {
            display: none; // dump fix
        }
    }

    .social-login {
        &__heading {
            margin-bottom: 1rem;
            width: 100%;

            &-text {
                padding: 0 1rem;
                font-size: 1.3rem;
                font-weight: 500;
                color: black;
            }
        }

        &__items {
            width: 100%;
        }

        &__btn {
            padding: 10px 20px;

            font-size: 1rem;
            color: #fff;

            display: flex;
            align-items: center;

            border: 1px solid var(--primary-color);
            border-radius: 12px;
            background-color: var(--primary-color);

            transition: all 0.3s;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            span {
                margin-right: 0.5rem;
                font-weight: 500;
            }

            svg {
                margin-left: auto;
            }

            &_facebook {
                background-color: #3b5998;
                border-color: #3b5998;

                &:hover {
                    background-color: #506696;
                    border-color: #506696;
                }
            }

            &_google {
                background-color: #db4437;
                border-color: #db4437;

                &:hover {
                    background-color: #f06b5f;
                    border-color: #f06b5f;
                }
            }
        }

        &__bottom {
            margin: 1rem 0;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                // background-color: #D2D9F4;
                background-color: cl(main, .2);
                border-radius: 8px;
            }

            &-text {
                position: relative;
                color: #7688a7;
                background-color: #fff;
                z-index: 1;
                padding: 0 1rem;
            }
        }
    }
}
