.pills {
    &__group {
        margin-bottom: 20px;
    }

    &__box {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        // flex: 0 0 calc(100% / 3 - 10px);
        flex-grow: 1;
        min-height: 52px;
        min-width: 150px;
        border-radius: 8px !important;
        border: 4px solid $grey-color;
        background: $grey-color;
        background-size: 21px 16px;
        background-position: 25px 16px;
        color: #3b454b;
        font-size: 14px;
        margin-bottom: 0;
        cursor: pointer;
        transition: $transition;

        &:hover {
            box-shadow: 0 10px 25px cl(main, .40);
            color: #fff;
            background: var(--main-color);
            background-size: 21px 16px;
            background-position: 25px 16px;
            border: 4px solid var(--main-color);
        }

        &.active {
            border: 4px solid var(--main-color);
            color: var(--main-color);
            box-shadow: none;
            background: #ffffff;
            background-size: 21px 16px;
            background-position: 25px 16px;
        }

        &:focus {
            box-shadow: 0 10px 25px cl(main, .40);
            border: 4px solid var(--main-color);
        }

        &:not(:first-child) {
            margin-left: 8px;
        }

        &:not(:last-child) {
            margin-right: 8px;

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }

        &:nth-child(2) {
            @include media-breakpoint-down(sm) {
                order: 1;
                flex: 100%;
                margin-top: 16px;
                margin-right: 0;
                margin-left: 0;
            }
        }

        @media (max-width: 380px) {
            margin-left: 0 !important;
            margin-right: 0 !important;
            flex: 100%;

            &:nth-child(2) {
                order: 0;
                margin-bottom: 16px;
                margin-top: 16px;
            }
        }

        span:nth-child(2) {
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
        }

        span:nth-child(3) {
            line-height: 15px;
        }
    }
}

// .pills__btn+.pills__btn {
//     margin-left: 15px;
// }
